import {
  filtersSchema,
  sortColumnSchema,
  DEFAULT_SORT,
  DEFAULT_FILTERS,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
  type TCheckoutFilters,
  type TSortColumn,
} from "@revana/api/utils";
import { createParser, parseAsInteger, parseAsIsoDateTime } from "nuqs/server";

const filterObjToString = (f: object) =>
  JSON.stringify(f, (_, value: unknown) => {
    if (value instanceof Date) {
      return value.toISOString();
    }
    return value;
  });

const parseFilters = createParser<TCheckoutFilters>({
  parse: (rawData) => filtersSchema.parse(JSON.parse(rawData)),
  serialize(objValue) {
    return JSON.stringify(
      objValue,
      (_, value: TCheckoutFilters[keyof TCheckoutFilters]) => {
        if (value instanceof Date) {
          return value.toISOString();
        }
        return value;
      }
    );
  },
  eq: (a, b) => filterObjToString(a) === filterObjToString(b),
});

const parseSortColumn = createParser<TSortColumn>({
  parse: (rawData) => sortColumnSchema.parse(JSON.parse(rawData)),
  serialize: (data) => filterObjToString(data),
  eq: (a, b) => a.column === b.column && a.sort === b.sort,
});

const checkoutFilterQueryParsers = {
  page: parseAsInteger.withDefault(1),
  filters: parseFilters.withDefault(DEFAULT_FILTERS),
  startDate: parseAsIsoDateTime.withDefault(DEFAULT_START_DATE),
  endDate: parseAsIsoDateTime.withDefault(DEFAULT_END_DATE),
  sortedColumn: parseSortColumn.withDefault(DEFAULT_SORT),
};

export { checkoutFilterQueryParsers };
