"use client";

import { use } from "react";

import { type RouterOutputs } from "@revana/api";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Skeleton,
  Spinner,
} from "@revana/ui";
import { useRouter } from "next/navigation";

import { APP_ROUTES } from "@web/features/navigation/constants";
import { api } from "@web/trpc/client";
import { formatCurrency } from "@web/utils";

interface RecentCheckoutsProps {
  recentCheckouts: Promise<RouterOutputs["store"]["getRecentCheckouts"]>;
}

const RecentCheckouts: React.FC<RecentCheckoutsProps> = ({
  recentCheckouts,
}) => {
  const router = useRouter();

  const initialData = use(recentCheckouts);

  const { data: checkouts, isRefetching } =
    api.store.getRecentCheckouts.useQuery(undefined, {
      placeholderData: (data) => data ?? initialData,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    });

  return (
    <Card className="relative">
      {isRefetching && (
        <div className="absolute right-2 top-2">
          <Spinner width="16" />
        </div>
      )}
      <CardHeader>
        <CardTitle>Recent Checkouts</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-6">
        {checkouts?.map((checkout) => {
          const firstChar = checkout.customer?.firstName?.charAt(0) ?? "";
          const formattedPrice = formatCurrency(
            checkout.totalPrice / 100,
            checkout.currency
          );

          return (
            <div
              className="relative flex cursor-pointer items-center justify-between gap-4"
              key={checkout.id}
              role="button"
              tabIndex={0}
              onClick={() => {
                const outreach = checkout.outreaches[0];

                if (!outreach) {
                  return;
                }

                const {
                  campaignGroupId,
                  campaignGroup: { campaignId },
                  id,
                } = outreach;

                router.push(
                  `${APP_ROUTES.campaigns}/${campaignId}/${campaignGroupId}/${id}`
                );
              }}
            >
              <div className="flex items-center gap-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-muted-foreground font-bold text-white">
                  {firstChar}
                </div>
                <div className="flex flex-col">
                  <div className="font-bold">
                    {checkout.customer?.firstName} {checkout.customer?.lastName}
                  </div>
                  <div className="text-sm text-gray-500">
                    {checkout.customer?.phoneNumber}
                  </div>
                </div>
              </div>
              <div className="font-bold">+{formattedPrice}</div>
            </div>
          );
        })}
        {!checkouts?.length && (
          <div className="text-center text-sm text-muted-foreground">
            No recent checkouts
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const FallbackRow = () => {
  return (
    <div className="flex h-10 w-full items-center gap-4">
      <Skeleton className="aspect-square h-full rounded-full" />
      <div className="h-full grow flex-col gap-2">
        <Skeleton className="h-4 w-1/2" />
        <Skeleton className="mt-1 h-4 w-2/3" />
      </div>
      <Skeleton className="ml-auto h-1/2 w-1/5" />
    </div>
  );
};

const RecentCheckoutsFallback = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Recent Checkouts</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-6">
        <FallbackRow />
        <FallbackRow />
        <FallbackRow />
        <FallbackRow />
        <FallbackRow />
      </CardContent>
    </Card>
  );
};

export { RecentCheckoutsFallback };

export default RecentCheckouts;
