import { endOfDay, startOfDay, subDays } from "date-fns";
import { z } from "zod";

const DEFAULT_DAYS_DIFF = 28;

export const filterOperatorEnum = z.enum(["gt", "lt", "equals"]);
export type TFilterOperator = z.infer<typeof filterOperatorEnum>;

export const sortOperatorEnum = z.enum(["asc", "desc"]);
export const sortColumnEnum = z.enum([
  "result",
  "revenueRecovered",
  "cartValue",
  "duration",
  "createdAt",
]);

export const sortColumnSchema = z.object({
  column: sortColumnEnum.optional().default("createdAt"),
  sort: sortOperatorEnum.optional().default("desc"),
});
export type TSortColumn = z.infer<typeof sortColumnSchema>;

export const callResultEnum = z.enum([
  "ANSWERED",
  "VOICEMAIL",
  "NO_ANSWER",
  "UNKNOWN",
]);
export const messageResultsEnum = z.enum([
  "REPLIED",
  "READ",
  "DELIVERED",
  "FAILED",
]);

export const StatusEnum = z.enum([
  "IN_PROGRESS",
  "FAILED",
  "COMPLETED",
  "SCHEDULED",
]);

export const DEFAULT_START_DATE = startOfDay(
  subDays(new Date(), DEFAULT_DAYS_DIFF)
);
export const DEFAULT_END_DATE = endOfDay(new Date());
export const DEFAULT_SORT: TSortColumn = {
  column: sortColumnEnum.enum.createdAt,
  sort: sortOperatorEnum.enum.desc,
};
export const DEFAULT_STATUS = [
  StatusEnum.Enum.IN_PROGRESS,
  StatusEnum.Enum.FAILED,
  StatusEnum.Enum.COMPLETED,
  StatusEnum.Enum.SCHEDULED,
];
export const DEFAULT_FILTERS: TCheckoutFilters = {
  status: DEFAULT_STATUS,
};

export const filtersSchema = z.object({
  status: z.array(StatusEnum).default(DEFAULT_STATUS),
  revenueRecovered: z
    .object({
      filterBy: filterOperatorEnum,
      value: z.number().nonnegative(),
    })
    .optional(),
  cartValue: z
    .object({
      filterBy: filterOperatorEnum,
      value: z.number().nonnegative(),
    })
    .optional(),
  duration: z
    .object({
      filterBy: filterOperatorEnum,
      value: z.number().nonnegative(),
    })
    .optional(),
  // createdAt: z
  //   .object({
  //     from: z.date().optional(),
  //     to: z.date().optional(),
  //   })
  //   .optional(),
  results: z
    .union([z.array(callResultEnum), z.array(messageResultsEnum)])
    .optional(),
});
export type TFilterSchemaKeys = keyof z.infer<typeof filtersSchema>;
export type TCheckoutFilters = z.infer<typeof filtersSchema>;

export const outreachFiltersSchema = z.object({
  campaignGroupId: z.string(),
  page: z.number().int().positive(),
  sort: sortColumnSchema.optional().default(DEFAULT_SORT),
  startDate: z.date().optional().default(DEFAULT_START_DATE),
  endDate: z.date().optional().default(DEFAULT_END_DATE),
  filters: filtersSchema.optional().default(DEFAULT_FILTERS),
});
