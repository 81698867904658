"use client";

import { useCallback } from "react";

import { useQueryStates } from "nuqs";

import { checkoutFilterQueryParsers } from "@web/libs/nuqs-search-params/constants";

export const useCheckoutFilters = () => {
  const [checkoutFilters, setCheckoutFilters] = useQueryStates(
    checkoutFilterQueryParsers
  );

  const resetFilters = useCallback(() => {
    void setCheckoutFilters(null);
  }, [setCheckoutFilters]);

  return {
    checkoutFilters,
    setCheckoutFilters,
    resetFilters,
  };
};
