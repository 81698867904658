import * as React from "react";

import { ChevronRight } from "lucide-react";

import { Button } from "./button";
import { cn } from "../libs/utils";

type BreadCrumbItem = {
  id: string;
  title?: string;
  onClick: () => void;
};

type IBreadCrumb = {
  items: BreadCrumbItem[];
  selected: string;
};

const BreadCrumb: React.FC<IBreadCrumb> = ({ items, selected }) => {
  const itemsToSelected = React.useMemo(
    () => items.slice(0, items.findIndex((item) => item.id === selected) + 1),
    [items, selected]
  );
  return (
    <div className="flex items-center gap-3 rounded-md border-[2px] border-solid border-muted px-3">
      {itemsToSelected.map((item, index) => (
        <Button
          key={String(item.id + index)}
          className={cn(
            "",
            {
              "text-primary": item.id === selected,
              "text-foreground/75": item.id !== selected,
            },
            "px-0 py-0"
          )}
          variant="link"
          onClick={() => item.onClick()}
        >
          <div>{item.title}</div>
          {index !== items.length - 1 && (
            <ChevronRight className="ml-3 h-4 w-4" />
          )}
        </Button>
      ))}
    </div>
  );
};

export { BreadCrumb };
