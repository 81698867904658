"use client";

import { useCallback, useState } from "react";

import { DatePicker as DatePickerWithRange } from "@revana/ui";

import { useDateRangeFilter } from "@web/hooks";

interface DateRange {
  from: Date | undefined;
  to?: Date | undefined;
}

const DateRangeSelector = () => {
  const [localStartDate, setLocalStartDate] = useState<Date | undefined>(
    undefined
  );
  const [localEndDate, setLocalEndDate] = useState<Date | undefined>(undefined);
  const { setStartDate, setEndDate, startDate, endDate } = useDateRangeFilter();

  const onSelectDate = useCallback(
    (date: DateRange | undefined) => {
      setLocalStartDate(date?.from);
      setLocalEndDate(date?.to);
    },
    [setLocalEndDate, setLocalStartDate]
  );

  const onCalendarClose = useCallback(() => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
  }, [localEndDate, localStartDate, setEndDate, setStartDate]);

  return (
    <DatePickerWithRange
      date={{
        from: localStartDate ?? startDate,
        to: localEndDate ?? endDate,
      }}
      setDate={onSelectDate}
      onClose={onCalendarClose}
    />
  );
};

export default DateRangeSelector;
