"use client";

import { useCallback } from "react";

import { endOfDay, startOfDay, subDays } from "date-fns";
import { useQueryState, parseAsIsoDateTime } from "nuqs";

const DEFAULT_DAYS_DIFF = 28;

export const DEFAULT_START_DATE = startOfDay(
  subDays(new Date(), DEFAULT_DAYS_DIFF)
);
export const DEFAULT_END_DATE = endOfDay(new Date());

const useDateRangeFilter = () => {
  const [startDateFilter, setStartDateFilter] = useQueryState(
    "startDate",
    parseAsIsoDateTime.withDefault(DEFAULT_START_DATE)
  );

  const [endDateFilter, setEndDateFilter] = useQueryState(
    "endDate",
    parseAsIsoDateTime.withDefault(DEFAULT_END_DATE)
  );

  const setDate = useCallback(
    (type: "start" | "end") => (date: Date | undefined) => {
      if (type === "start") {
        void setStartDateFilter(date ? startOfDay(date) : DEFAULT_START_DATE);
      } else {
        void setEndDateFilter(date ? endOfDay(date) : DEFAULT_END_DATE);
      }
    },
    [setEndDateFilter, setStartDateFilter]
  );

  return {
    startDate: startDateFilter,
    endDate: endDateFilter,
    setStartDate: setDate("start"),
    setEndDate: setDate("end"),
  };
};

export default useDateRangeFilter;
