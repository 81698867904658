"use client";

import React, { use, useMemo } from "react";

import { type RouterOutputs } from "@revana/api";
import {
  type ChartConfig,
  Skeleton,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Icon,
  Spinner,
} from "@revana/ui";
import { useSearchParams } from "next/navigation";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

import { api } from "@web/trpc/client";
import { parseDate } from "@web/utils";

const chartConfig = {
  value: {
    label: "Value",
    color: "hsl(var(--primary))",
  },
} satisfies ChartConfig;

interface CheckoutFunnelProps {
  initialAnalytics: Promise<RouterOutputs["store"]["getOutreachAnalytics"]>;
}

const CheckoutFunnel: React.FC<CheckoutFunnelProps> = ({
  initialAnalytics,
}) => {
  const searchParams = useSearchParams();

  const dateRange = useMemo(() => {
    return {
      startDate: parseDate(searchParams.get("startDate")),
      endDate: parseDate(searchParams.get("endDate")),
    };
  }, [searchParams]);

  const initialData = use(initialAnalytics);

  const analyticsQuery = api.store.getOutreachAnalytics.useQuery(
    {
      dateRange: dateRange,
    },
    {
      placeholderData: (data) => data ?? initialData,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const { data: analytics, isRefetching } = analyticsQuery;

  const chartData = useMemo(() => {
    if (!analytics) {
      return [];
    }
    return [
      {
        label: "Abandoned Checkouts",
        value: analytics.abandonedCheckouts.currentPeriod.toFixed(0),
      },
      {
        label: "Outreach Attempts",
        value: analytics.outreachAttempts.currentPeriod.toFixed(0),
      },
      {
        label: "Successful Outreaches",
        value: analytics.successfulOutreaches.currentPeriod.toFixed(0),
      },
      {
        label: "Checkouts Recovered",
        value: analytics.checkoutsRecovered.currentPeriod.toFixed(0),
      },
    ];
  }, [analytics]);

  const isInitialLoading = useMemo(() => {
    return (
      analytics?.previousPeriod.startDate ===
        initialData?.previousPeriod.startDate &&
      analytics?.previousPeriod.endDate === initialData?.previousPeriod.endDate
    );
  }, [analytics, initialData]);

  const isNoData = useMemo(() => {
    return chartData.reduce((acc, curr) => acc + Number(curr.value), 0) === 0;
  }, [chartData]);

  return (
    <Card className="relative">
      {isRefetching && !isInitialLoading && (
        <div className="absolute right-4 top-4">
          <Spinner width="24" />
        </div>
      )}
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          Checkout Funnel <Icon className="h-6 w-6" name="trending-up" />
        </CardTitle>
      </CardHeader>
      <CardContent>
        {isNoData ? (
          <div className="text-center text-sm text-muted-foreground">
            No Checkout Data Available
          </div>
        ) : (
          <ChartContainer config={chartConfig}>
            <BarChart accessibilityLayer data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis axisLine={false} dataKey="label" tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <ChartTooltip
                content={<ChartTooltipContent hideLabel />}
                cursor={false}
              />
              <Bar dataKey="value" fill="var(--color-value)" radius={8} />
            </BarChart>
          </ChartContainer>
        )}
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        {/* <div className="flex gap-2 font-medium leading-none">
          Trending up by 5.2% this month{" "}
          <Icon name="trending-up" className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing total visitors for the last 6 months
        </div> */}
      </CardFooter>
    </Card>
  );
};

const CheckoutFunnelFallback = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Checkout Funnel</CardTitle>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <Skeleton className="h-full w-full" />
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          <Skeleton className="h-4 w-28" />
          <Skeleton className="h-4 w-4 rounded-full" />
        </div>
        <Skeleton className="h-4 w-48" />
      </CardFooter>
    </Card>
  );
};

export { CheckoutFunnelFallback };

export default CheckoutFunnel;
