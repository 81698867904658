"use client";
import { useCallback, useEffect, useRef, useState } from "react";

import { useQueryStates } from "nuqs";

import { checkoutFilterQueryParsers } from "@web/libs/nuqs-search-params/constants";
import { getNestedValue, setNestedValue } from "@web/utils";

import {
  DEFAULT_STATUS,
  DEFAULT_SORT,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from "./types";

export const useCheckoutFilterState = <
  K extends Flatten<typeof checkoutFilters>,
>(
  filterKey: K
): [
  NestedValue<typeof checkoutFilters, K>,
  (
    payload: Partial<NestedValue<typeof checkoutFilters, K>>,
    apply?: boolean
  ) => void,
  (apply?: boolean) => void,
] => {
  const [checkoutFilters, setCheckoutFilters] = useQueryStates(
    checkoutFilterQueryParsers
  );

  const defaultStates = useRef<{
    [key in Flatten<typeof checkoutFilters>]?:
      | NestedValue<typeof checkoutFilters, key>
      | undefined;
  }>({
    "filters.status": DEFAULT_STATUS,
    sortedColumn: DEFAULT_SORT,
    page: 1,
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  });

  const [localCheckoutFilters, setLocalCheckoutFilters] =
    useState<typeof checkoutFilters>(checkoutFilters);

  const getValue = useCallback(
    <T extends Flatten<typeof checkoutFilters>>(key: T) => {
      return getNestedValue(localCheckoutFilters, key);
    },
    [localCheckoutFilters]
  );

  const updateFilter = useCallback(
    (
      payload: Partial<NestedValue<typeof checkoutFilters, K>>,
      apply = false
    ) => {
      const updatedCheckoutFilters = setNestedValue(
        localCheckoutFilters,
        filterKey,
        payload
      );

      if (filterKey.includes("sortedColumn")) {
        updatedCheckoutFilters.page = 1;
      }

      setLocalCheckoutFilters(updatedCheckoutFilters);

      if (apply) {
        void setCheckoutFilters(updatedCheckoutFilters);
      }
    },
    [filterKey, localCheckoutFilters, setCheckoutFilters]
  );

  useEffect(() => {
    setLocalCheckoutFilters(checkoutFilters);
  }, [checkoutFilters]);

  const resetFilter = useCallback(
    (apply = false) => {
      if (!defaultStates.current) {
        return;
      }
      const defaultState = defaultStates.current[filterKey];
      const updatedCheckoutFilters = setNestedValue(
        localCheckoutFilters,
        filterKey,
        defaultState
      );

      setLocalCheckoutFilters(updatedCheckoutFilters);

      if (apply) {
        void setCheckoutFilters(updatedCheckoutFilters);
      }
    },
    [filterKey, localCheckoutFilters, setCheckoutFilters]
  );

  const currentValue = getValue(filterKey);

  return [currentValue, updateFilter, resetFilter];
};
